import actions from './actions'
import mutations from './mutations'
import getters from './getters'

const state = {
  images: [],
  combinations: [],
  videoSources: [],
  videoSourcesPage: 1,
  videoSourcesPerPage: 0,
  videoSourcesTotal: 0,
  videoSourcesTotalPages: 0,
  isSendDataLoading: false,
  isSaveDataLoading: false,
  isDataDownload: false,
  isStartVideoAnalyticLoading: false,
  videoAnalyticMessages: [],
  selectedImage: {
    src: null,
    id: null,
    type: null,
  },
  selectedCombination: {
    image1: {
      src: null,
      id: null,
      type: null,
      polygonData: {
        polygons: [],
        selectedPolygon: null,
        polygonCount: 0,
      },
      doteData: {
        dotes: [],
        selectedDote: null,
        doteCount: 0,
      },
    },
    image2: {
      src: null,
      id: null,
      type: null,
      polygonData: {
        polygons: [],
        selectedPolygon: null,
        polygonCount: 0,
      },
      doteData: {
        dotes: [],
        selectedDote: null,
        doteCount: 0,
      },
    },
  },
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
}
