import actions from './actions'
import mutations from './mutations'
import getters from './getters'

const state = {
  projectList: [],
  projectType: 'Текущие проекты',
  selectedProjectId: null,
  importProjectId: null,
  isProjectCreate: false,
  isProjectLoading: false,
  isProjectDelete: false,
  isProjectRestore: false,
  isProjectClone: false,
  isProjectArchive: false,
  isProjectUnArchive: false,
  isAprProjectMove: false,
  projectMyTaskToday: 'all',
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
}
