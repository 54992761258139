function findCombinationIndex(combinations, combination) {
  const imageIds = [combination.image1.id, combination.image2.id]
  return combinations.findIndex(item => imageIds.includes(item?.image1.id) && imageIds.includes(item?.image2.id))
}

function getImageKey(combination, imageId) {
  return combination.image1.id === imageId ? 'image1' : 'image2'
}

export default {
  setCombination(state, images) {
    if (images && images.length === 2) {
      const imageIdList = images.map(image => image.id)
      const combinationExists = state.combinations.some(
        combination => imageIdList.includes(combination?.image1.id) && imageIdList.includes(combination?.image2.id),
      )

      if (!combinationExists) {
        state.combinations.push({ image1: images[0], image2: images[1] })
      }
    }
  },

  setSelectedCombination(state, combination) {
    if (combination) state.selectedCombination = combination
  },

  setImage(state, image) {
    if (image && !state.images.some(item => item.id === image.id)) {
      state.images.push(image)
    }
  },

  setSelectedImage(state, data) {
    if (data) state.selectedImage = data
  },

  deleteImage(state, imageId) {
    if (imageId) {
      state.images = state.images.filter(image => image.id !== imageId)

      if (state.selectedImage?.id === imageId) {
        state.selectedImage = { src: null, id: null, domed: null }
      }
    }
  },

  resetSelectedImage(state) {
    state.selectedImage = { src: null, id: null, domed: null }
  },

  setVideoSources(state, data) {
    if (data) {
      state.videoSources = [...state.videoSources, ...data]
    }
  },

  setTotal(state, total) {
    if (total !== undefined && total !== null) {
      state.videoSourcesTotal = total
    }
  },

  setTotalPage(state, pages) {
    if (pages !== undefined && pages !== null) {
      state.videoSourcesTotalPages = pages
    }
  },

  setPerPage(state, pages) {
    if (pages !== undefined && pages !== null) {
      state.videoSourcesPerPage = pages
    }
  },

  createPolygon(state, data) {
    if (data) {
      const combinationIndex = findCombinationIndex(state.combinations, data.combination)
      if (combinationIndex !== -1) {
        const combination = state.combinations[combinationIndex]
        const imageKey = getImageKey(combination, data.imageId)
        const imageData = combination[imageKey]

        imageData.polygonData.polygons.push(data.polygon)
        imageData.polygonData.polygonCount = data.polygon.count + 1 // Инкрементируем счётчик полигонов
      }
    }
  },

  createDote(state, data) {
    if (data) {
      const imageIndex = state.images.findIndex(image => image.id === data.imageId)
      if (imageIndex !== -1) {
        const imageData = state.images[imageIndex]
        imageData.doteData.dotes.push(data.dote)
        imageData.doteData.doteCount = data.dote.count + 1 // Инкрементируем счётчик точек
      }
    }
  },

  setSelectedPolygon(state, data) {
    if (data) {
      const combinationIndex = findCombinationIndex(state.combinations, data.combination)
      if (combinationIndex !== -1) {
        const combination = state.combinations[combinationIndex]
        const imageKey = getImageKey(combination, data.imageId)
        combination[imageKey].polygonData.selectedPolygon = data.polygonId
      }
    }
  },

  addPointToPolygon(state, data) {
    if (data) {
      const combinationIndex = findCombinationIndex(state.combinations, data.currentCombination)
      if (combinationIndex !== -1) {
        const combination = state.combinations[combinationIndex]
        const imageKey = getImageKey(combination, data.imageId)
        const polygon = combination[imageKey].polygonData.polygons.find(polygon => polygon.id === data.polygonId)
        if (polygon) {
          polygon.points.push(data.point)
        }
      }
    }
  },

  addPointToDotes(state, data) {
    if (data) {
      const combinationIndex = findCombinationIndex(state.combinations, data.combination)
      if (combinationIndex !== -1) {
        const combination = state.combinations[combinationIndex]
        const imageKey = getImageKey(combination, data.imageId)
        combination[imageKey].doteData.dotes.push(data.point)
      }
    }
  },

  updatePointForPolygon(state, data) {
    if (data) {
      const combinationIndex = findCombinationIndex(state.combinations, data.combination)
      if (combinationIndex !== -1) {
        const combination = state.combinations[combinationIndex]
        const imageKey = getImageKey(combination, data.imageId)
        const polygon = combination[imageKey].polygonData.polygons.find(polygon => polygon.id === data.polygonId)
        if (polygon) {
          const pointIndex = polygon.points.findIndex(point => point.id === data.point.id)
          if (pointIndex !== -1) {
            polygon.points[pointIndex] = data.point
          }
        }
      }
    }
  },

  updatePointForDote(state, data) {
    if (data) {
      const combinationIndex = findCombinationIndex(state.combinations, data.combination)
      if (combinationIndex !== -1) {
        const combination = state.combinations[combinationIndex]
        const imageKey = getImageKey(combination, data.imageId)
        const doteIndex = combination[imageKey].doteData.dotes.findIndex(dote => dote.id === data.doteId)
        if (doteIndex !== -1) {
          combination[imageKey].doteData.dotes[doteIndex] = data.point
        }
      }
    }
  },

  deletePointFromPolygon(state, data) {
    if (data) {
      const combinationIndex = findCombinationIndex(state.combinations, data.combination)
      console.info('state.combinations', state.combinations)
      console.info('combinationIndex', combinationIndex)
      if (combinationIndex !== -1) {
        const combination = state.combinations[combinationIndex]
        const imageKey = getImageKey(combination, data.imageId)
        const polygon = combination[imageKey].polygonData.polygons.find(polygon => polygon.id === data.polygonId)
        console.info('polygon', polygon)
        if (polygon) {
          polygon.points = polygon.points.filter(point => point.id !== data.pointId)
        }
      }
    }
  },

  deletePolygon(state, data) {
    if (data) {
      const combinationIndex = findCombinationIndex(state.combinations, data.combination)
      if (combinationIndex !== -1) {
        const combination = state.combinations[combinationIndex]
        const imageKey = getImageKey(combination, data.imageId)
        combination[imageKey].polygonData.polygons = combination[imageKey].polygonData.polygons.filter(
          polygon => polygon.id !== data.polygonId,
        )
      }
    }
  },

  deleteDote(state, data) {
    if (data) {
      const combinationIndex = findCombinationIndex(state.combinations, data.combination)
      if (combinationIndex !== -1) {
        const combination = state.combinations[combinationIndex]
        const imageKey = getImageKey(combination, data.imageId)
        combination[imageKey].doteData.dotes = combination[imageKey].doteData.dotes.filter(
          dote => dote.id !== data.doteId,
        )
      }
    }
  },

  deleteEmptyPolygons(state, data) {
    if (data) {
      const combinationIndex = findCombinationIndex(state.combinations, data.combination)
      if (combinationIndex !== -1) {
        const combination = state.combinations[combinationIndex]
        ;['image1', 'image2'].forEach(imageKey => {
          // Точка с запятой нужно чтобы явно объяснить, что не операция над одним массивом
          combination[imageKey].polygonData.polygons = combination[imageKey].polygonData.polygons.filter(
            polygon => polygon.points.length > 0,
          )
        })
      }
    }
  },

  recalculationPolygonCounts(state, data) {
    if (data) {
      const combinationIndex = findCombinationIndex(state.combinations, data.combination)
      if (combinationIndex !== -1) {
        const combination = state.combinations[combinationIndex]
        ;['image1', 'image2'].forEach(imageKey => {
          // Точка с запятой нужно чтобы явно объяснить, что не операция над одним массивом
          const polygons = combination[imageKey].polygonData.polygons
          combination[imageKey].polygonData.polygonCount = polygons.length
          polygons.forEach((polygon, index) => {
            polygon.count = index
          })
        })
      }
    }
  },

  setSendDataLoading(state, status) {
    state.isSendDataLoading = status
  },

  setSaveDataLoading(state, status) {
    state.isSaveDataLoading = status
  },

  setDataDownloading(state, status) {
    state.isDataDownload = status
  },

  setStartVideoAnalyticLoading(state, status) {
    state.isStartVideoAnalyticLoading = status
  },

  addVideoAnalyticMessages(state, payload) {
    state.videoAnalyticMessages.push(payload)
  },

  resetState(state) {
    state.images = []
    state.combinations = []
    state.videoSources = []
    state.videoSourcesPage = 1
    state.videoSourcesPerPage = 0
    state.videoSourcesTotal = 0
    state.videoSourcesTotalPages = 0
    state.selectedImage = {
      src: null,
      id: null,
      domed: null,
      name: null,
    }
    state.selectedCombination = {
      image1: {
        src: null,
        id: null,
        type: null,
        polygonData: {
          polygons: [],
          selectedPolygon: null,
          polygonCount: 0,
        },
        doteData: {
          dotes: [],
          selectedDote: null,
          doteCount: 0,
        },
      },
      image2: {
        src: null,
        id: null,
        type: null,
        polygonData: {
          polygons: [],
          selectedPolygon: null,
          polygonCount: 0,
        },
        doteData: {
          dotes: [],
          selectedDote: null,
          doteCount: 0,
        },
      },
    }
  },
}
