import actions from './actions'
import getters from './getters'
import mutations from './mutations'

const state = {
  videoSources: [],
  domedVideoSources: [],
  perPage: undefined,
  totalRows: undefined,
  domedTotalRows: undefined,
  totalPage: undefined,
  selectedVideoSource: undefined,
  needOpenModalForNewVideoSource: false,
  formIsValid: true,
  fullScreenVideoWall: false,
  videoSourcesList: [],
}

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
}
