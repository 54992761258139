import axios from '../axios'
import { deleteAllCookies } from '@/Utils/helpers'

export default {
  async signOut({ commit }) {
    commit('clearUser')
    commit('TaskList/resetFilters', undefined, { root: true })
    commit('TaskList/resetSearch', undefined, { root: true })
    commit('StatusHistory/resetFilters', undefined, { root: true })
    commit('StatusHistory/resetSearch', undefined, { root: true })
    window.localStorage.routes = null
    window.parent.postMessage(
      {
        type: 'AUTH_LOGOUT',
        data: {},
      },
      '*',
    )
    try {
      await axios.post('/logout')
    } catch (e) {
      if (e.handled) return
    }

    deleteAllCookies()
    delete axios.defaults.headers.Authorization
  },
  async signIn({ getters, dispatch, commit }, form) {
    await axios.get(process.env.VUE_APP_BACKEND_BASE + '/sanctum/csrf-cookie')
    try {
      const responseLogin = await axios.post('/login', {
        email: form.email,
        password: form.password,
      })
      if (responseLogin) {
        axios.defaults.headers.Authorization = 'Bearer ' + responseLogin.token
        // dispatch('Roles/downloadListRoles', { args: '?per_page=10000' }, { root: true })
        const responseAuth = await dispatch('UserList/userAuth', null, { root: true })
        if (responseAuth?.message && responseAuth.message === 'Смените пароль') {
          commit('setFirstLogin', responseAuth.user.id)
        } else {
          commit('setFirstLogin', undefined)
          commit('setUser', responseLogin.user)
          commit('User/setSelectProfile', responseLogin.user, { root: true })
          commit('setUserRoles', responseLogin.user.roles)
          commit('Education/setEducations', responseLogin.user.educations, { root: true })

          localStorage.setItem('token', responseLogin.token)
          localStorage.setItem('accesses', JSON.stringify(getters.userAccesses))
        }
      }
      return true
    } catch (error) {
      console.error('Ошибка авторизации', error)
      throw error.response?.data
    }
  },
  async signInFace({ commit }, photo) {
    await axios.get(process.env.VUE_APP_BACKEND_BASE + '/sanctum/csrf-cookie')
    try {
      const responseLogin = await axios.post('/auth-face', { photo })
      commit('setUser', responseLogin.user)
      localStorage.setItem('token', responseLogin.token)
      axios.defaults.headers.Authorization = 'Bearer ' + responseLogin.token
    } catch (e) {
      if (e.handled) return
      commit('setErrors', e)
      commit('Notify/setError', 'При входе в систему произошла ошибка. Попробуйте позже.', { root: true })
    }
  },
}
