import axios from '../axios'
import { getSortedInstrumentList } from '@/components/views/Pages/ProjectAndTask/utils/common'

async function downloadTask(rootState, params) {
  const filterResponsibleIsChecked = Number(rootState.Task.filterResponsibleIsChecked)
  const teams = rootState.Auth.user.teams
  const teamsParam = {}
  const workZoneParam = {}
  const instrumentParam = {}

  teams.forEach((team, index) => {
    teamsParam[`filter[teams][${index}]`] = team.id
  })

  if (params?.workZoneId && params?.workZoneId.length) {
    params.workZoneId.forEach((id, index) => {
      workZoneParam[`filter[work_zones][${index}]`] = id
    })
  }

  if (params?.instrumentId && params?.instrumentId.length) {
    params.instrumentId.forEach((id, index) => {
      workZoneParam[`filter[instruments][${index}]`] = id
    })
  }

  const needShowState = !filterResponsibleIsChecked && !params?.assigned && !params?.responsible
  const projectType = Number(rootState.Project.projectType === 'Проекты АПР')
  const filters = {
    ...teamsParam,
    ...workZoneParam,
    ...instrumentParam,
    'filter[state]': Number(needShowState),
    'sort[column]': params?.sortValue,
    'sort[type]': params?.sortType,
    'filter[projects][0]': params?.projectId,
    'filter[my_tasks_today][responsible]': params?.my_tasks_today?.responsible,
    'filter[my_tasks_today][assigned]': params?.my_tasks_today?.assigned,
    'filter[my_tasks_today]': filterResponsibleIsChecked || undefined,
    'filter[assigned][0]': params?.assigned,
    'filter[responsible][0]': params?.responsible,
    'filter[kanban]': params?.isKanban,
    gantt: 0,
    search: params?.search,
    page: params?.currentPage,
  }

  if (projectType) {
    filters['filter[is_plan_repair]'] = projectType
  }

  const response = await axios.get('/list/tasks/search', {
    params: filters,
  })

  const tasks = Array.isArray(response.data)
    ? response.data.map(task => ({ ...task, parent: null }))
    : Object.entries(response.data || {})
        .filter(([key]) => key !== 'parent')
        .map(([key, value]) => ({ ...value, parent: null }))
  return [response, tasks]
}

// Получение списка задач
export default {
  async downloadTaskList({ rootState, commit }, params) {
    try {
      commit('setTaskLoading', true)
      const [response, data] = await downloadTask(rootState, params)
      commit('setTaskList', data)
      commit('setTaskPageData', response)
    } catch (error) {
      console.error('error', error)
      if (!error.logout) {
        commit('Notify/setError', 'При загрузке списка рабочих заданий произошла ошибка. Попробуйте позже.', {
          root: true,
        })
      }
    } finally {
      commit('setTaskLoading', false)
    }
  },
  async downloadKanbanTaskList({ rootState, commit }, params) {
    try {
      const [response, data] = await downloadTask(rootState, params)
      const payload = { data, hasSearch: params.search }
      commit('setKanbanTaskList', payload)
      commit('setTaskPageDataForScroll', response)
    } catch (error) {
      console.error('error', error)
      if (!error.logout) {
        commit('Notify/setError', 'При загрузке списка рабочих заданий произошла ошибка. Попробуйте позже.', {
          root: true,
        })
      }
    } finally {
      commit('setTaskLoading', false)
    }
  },
  async downloadSubtaskList({ commit }, id) {
    try {
      const response = (await axios.get(`/task/${id}/subtasks/list`)) || []
      const data = response.map(task => ({ ...task, parent: id }))
      commit('setSubTaskList', data)
      return data
    } catch (error) {
      if (!error.logout)
        commit('Notify/setError', 'При загрузке списка подзадач произошла ошибка. Попробуйте позже.', { root: true })
    }
  },
  async updateTaskTree(
    { rootState, commit },
    { node, isProfileTasks = false, profileParams = { assigned: null, responsible: null } },
  ) {
    if (!node.state_id) {
      const params = {
        projectId: rootState.Project.selectedProjectId,
        currentPage: rootState.Task.currentPage,
        assigned: isProfileTasks ? profileParams.assigned : null,
        responsible: isProfileTasks ? profileParams.responsible : null,
      }
      const [response, data] = await downloadTask(rootState, params)
      commit('updateTaskList', data)
      commit('setTaskPageData', response)
      return data
    }
    if (node.state_id || node.parent) {
      const data = await axios.get(`/get_list_task_branch?task_id=${node.id}`)
      if (node.state_id) rootState.Task.taskList = rootState.Task.taskList.filter(x => x.parent !== node.state_id)
      commit('updateSubTaskList', data)
      return data
    }
  },
  // Получить задачу по id
  async getCurrentTask(_, taskId) {
    try {
      const response = await axios.get(`/tasks/${taskId}`)

      return {
        ...response,
        instrument_types: getSortedInstrumentList(response.instrument_types), // Сортирую интсрументы для более корректной работы с объектом далее
      }
    } catch (error) {
      console.error(error)
    }
  },
  async getListTaskVideoSources({ rootState, commit }) {
    try {
      const res = await axios.get(`/work_zone/get_list_video_source?per_page=1000`)
      commit('setTaskVideoSources', res.data)
      return res.data
    } catch (error) {
      console.error(error)
    }
  },
  async updateDeleteTask({ rootState, commit }, payload) {
    let { node, isProfileTasks, profileParams } = payload
    const checkRootNodes = () => {
      const parentNode = rootState.Task.taskList.find(task => task.id === node.state_id)
      const childrenCount = rootState.Task.taskList.filter(task => task.parent === node.state_id)?.length
      return childrenCount === 1 && parentNode?.parent === null
    }
    if (!node.parent || checkRootNodes()) {
      const params = {
        projectId: rootState.Project.selectedProjectId,
        currentPage: rootState.Task.currentPage,
        assigned: isProfileTasks ? profileParams.assigned : null,
        responsible: isProfileTasks ? profileParams.responsible : null,
      }
      const [response, data] = await downloadTask(rootState, params)
      commit('updateTaskList', data)
      commit('setTaskPageData', response)
      return
    }
    if (node.parent) {
      rootState.Task.taskList = rootState.Task.taskList.filter(task => task.id !== node.id)
      const nextNode = rootState.Task.taskList.find(task => task.parent === node.parent)
      if (nextNode) node = nextNode
      if (!nextNode) node = rootState.Task.taskList.find(task => task.id === node.parent)
      const data = await axios.get(`/get_list_task_branch?task_id=${node.id}`)
      commit('updateSubTaskList', data)
    }
  },
  // Удаление задач из списка
  async deletedTask({ dispatch, commit }, payload) {
    const { task, isProfileTasks, profileParams } = payload
    try {
      commit('setTaskDeleteLoading', true)
      await axios.delete(`tasks/${task.id}`)
      await dispatch('updateDeleteTask', { node: task, isProfileTasks, profileParams })
      // commit('deleteTask', task)
    } catch (e) {
      commit('Notify/setError', 'При удалении рабочего задания произошла ошибка. Попробуйте позже.', { root: true })
    } finally {
      commit('setTaskDeleteLoading', false)
    }
  },
  // Получения списка задач, которые находятся на критическом пути
  async getCriticalPathTasks(_, projectId) {
    try {
      return await axios.get('gantt/get_list_task_on_critical_path', {
        params: {
          'projects[0]': projectId,
        },
      })
    } catch (error) {
      console.error(error)
    }
  },
  async taskPause(
    { dispatch, commit },
    { id, isProfileTasks = false, profileParams = { assigned: null, responsible: null } },
  ) {
    try {
      const response = await axios.put(`tasks/${id}/pause`)
      await dispatch('Task/updateTaskTree', { node: response, isProfileTasks, profileParams }, { root: true })
      return response
    } catch (error) {
      console.error(error)
      commit('Notify/setError', 'Не удалось поставить на паузу. Попробуйте позже.', { root: true })
    }
  },
  async taskPlay(
    { dispatch, commit },
    { id, isProfileTasks = false, profileParams = { assigned: null, responsible: null } },
  ) {
    try {
      const response = await axios.put(`tasks/${id}/play`)
      await dispatch('Task/updateTaskTree', { node: response, isProfileTasks, profileParams }, { root: true })
      return response
    } catch (error) {
      console.error(error)
      commit('Notify/setError', 'Не удалось снять с паузы. Попробуйте позже.', { root: true })
    }
  },
  // Редактирование задачи
  async editTask({ commit }, payload) {
    try {
      const { taskId, data } = payload
      const params = {
        name: data?.name, // Название задачи (string | undefined)
        description: data?.description, // Описание задачи(string | undefined)
        instruments: data?.instrument_types?.map(instrument => instrument.id), // Массив UUID инструментов (string[] | undefined)
        responsible: data?.responsible?.map(item => item.id) ?? undefined, // Массив UUID ответственных пользователей (string[] | undefined)
        assigned: data?.assigned, // Массив UUID назначенных пользователей (string[] | undefined)
        position_assigned: data?.position_assigned?.map(item => {
          return {
            id: item?.id,
            position_id: item.position?.id,
            user_id: item.user?.id,
          }
        }), // Массив объектов с информацией о назначенных позициях (Array<{}> | undefined)
        files: data?.files, // Массив UUID файлов (string[] | undefined)
        position: data?.position, // Массив объектов позиций
        warehouse: data?.warehouse, // Массив UUID складов(string[] | undefined)
        teams: data?.teams?.map(team => team.id) ?? undefined, // Массив UUID команд (string[] | undefined)
        work_zone_id: data?.work_zone_id, // UUID рабочей зоны (string | undefined)
        state_id: data?.state_id, // UUID состояния задачи (string | undefined)
        location_id: data?.location_id, // UUID локации (string | undefined)
        project_id: data?.project?.id, // UUID проекта (string | undefined)
        priority: data?.priority, // Приоритет задачи (number | undefined)
        start_date: data?.start_date, // Дата начала (string | Date | undefined)
        plan_date: data?.plan_date, // Плановая дата (string | Date | undefined)
        deadline: data?.deadline, // Крайний срок (string | Date | undefined)
        time_to_complete: data?.time_to_complete, // Время на выполнение (number | undefined)
        time_to_complete_sec: data?.time_to_complete_sec, // Время на выполнение в секундах (number | undefined)
        target: data?.target, // Цель задачи (string | undefined)
        repair_object_id: data?.repair_object_id, // UUID объекта ремонта (string | undefined)
        copy: data?.copy, // Флаг копирования (boolean)
        // equipment_id: payload?.equipment_id, // UUID оборудования (number | undefined | null)
        // status_id: payload?.status_id, // UUID статуса (string | undefined)
      }

      const response = await axios.put(`tasks/${taskId}`, {
        ...params,
      })

      return response
    } catch (error) {
      commit('Notify/setError', 'При сохранении произошла ошибка. Попробуйте позже.', { root: true })
      console.error(error)
    }
  },
  // Редактировать оборудование задачи
  async editEquipment({ commit }, payload) {
    const { taskId, data } = payload
    try {
      const response = await axios.put(`task/${taskId}/update/equipment`, data)
      return response.equipment
    } catch (error) {
      console.error(error)
      commit('Notify/setError', 'При редактировании оборудования произошла ошибка. Попробуйте позже.', { root: true })
    }
  },
  // Смена статуса задачи
  async editStatus({ commit }, payload) {
    const { taskId, data } = payload
    try {
      const response = await axios.put(`task/${taskId}/change-status`, data)
      return response[taskId] // Бэк возвращает объект задач с изменёнными статусами, где ключи id задач
    } catch (error) {
      console.error(error)
      if (error.handled) return
      commit('Notify/setError', 'При смене статуса произошла ошибка. Попробуйте позже.', { root: true })
    }
  },
  // Получение списка оборудования
  async downloadEquipmentList({ commit }) {
    try {
      const response = await axios.get('repairs/equipment/list')
      return response.data
    } catch (error) {
      console.error(error)
      if (!error.logout) {
        commit('Notify/setError', 'При загрузке списка оборудования произошла ошибка. Попробуйте позже.', {
          root: true,
        })
      }
    }
  },
  // Проверка для перевода задачи в статус "готово" при завершение суммарной
  async checkBranchBeforeCloseTask({ commit }, taskId) {
    try {
      const params = {
        task_id: taskId,
      }

      const response = await axios.post('check_branch_before_close_task', {
        ...params,
      })

      if (response.data === false) {
        return response.data
      } else {
        return response
      }
    } catch (error) {
      console.error(error)
      if (!error.logout) {
        commit('Notify/setError', 'При проверке статуса суммарной задаче произола ошибка. Попробуйте позже.', {
          root: true,
        })
      }
    }
  },
  // Получение списка инструментов для подбора эквивалента
  async downloadInstrumentsEquivalentList({ commit, rootGetters }, payload) {
    const statuses = rootGetters.configs.statuses
    const inModule = statuses.find(el => el.name === 'in_module')
    const use = statuses.find(el => el.name === 'use')
    const issued = statuses.find(el => el.name === 'issued')

    const search = payload ? `&search=${payload}` : ''

    try {
      return await axios.get(
        `tasks/instrument/list?${search}`,
      )
    } catch (error) {
      console.error(error)
      if (!error.logout) {
        commit('Notify/setError', 'При загрузке списка произошла ошибка. Попробуйте позже.', { root: true })
      }
    }
  },
  // Замена одного инструмента на эквивалентный
  async replaceInstruments(_, payload) {
    try {
      await axios.post('/instruments/equivalent/attach', payload)
    } catch (error) {
      console.error(error)
    }
  },
  // Получение разницы между количеством инструментов, указанных в задаче и фактическим количеством на складе
  async getDiffInstruments({ commit }, taskId) {
    try {
      return await axios.get(`tasks/${taskId}/fill/module`)
    } catch (e) {
      console.error(error)
      if (!e.logout) {
        commit('Notify/setError', 'При загрузке списка произошла ошибка. Попробуйте позже.', { root: true })
      }
    }
  },
  // Получение списка персонала для истории персонала
  async downloadTaskUsagePersonal({ commit }, taskId) {
    try {
      const response = await axios.get(`tasks/${taskId}/usage/users`)
      console.info('response', response)
      return response
    } catch (error) {
      console.error(error)
      if (!error.logout) {
        commit('Notify/setError', 'При загрузке списка произошла ошибка. Попробуйте позже.', { root: true })
      }
    }
  },
  // Создать комментарий
  async addComment({ commit }, params) {
    try {
      const response = await axios.post('comments', params)
      return response
    } catch (error) {
      console.info(error)
      commit('Notify/setError', 'При сохранении произошла ошибка. Попробуйте позже.', { root: true })
    }
  },
  // Удалить комментарий
  async deleteComment({ commit }, commentId) {
    try {
      const response = await axios.delete(`comments/${commentId}`)
      return response
    } catch (error) {
      console.info(error)
      commit('Notify/setError', 'При удалении произошла ошибка. Попробуйте позже.', { root: true })
    }
  },
  // Удалить комментарий
  async editComment({ commit }, params) {
    try {
      console.info('params', params)
      const response = await axios.put(`comments/${params.commentId}`, {
        text: params?.newText,
        files: params?.files,
      })
      return response
    } catch (error) {
      console.info(error)
      commit('Notify/setError', 'При редактировании произошла ошибка. Попробуйте позже.', { root: true })
    }
  },
  async uploadFiles(_, files) {
    console.info('uploadFiles', files)
    const data = new FormData()
    files.map((file, index) => {
      data.set(`files[${index}]`, file)
      return file
    })
    try {
      const response = await axios.post('upload-file', data)
      if (!Array.isArray(response)) return
      return response.map(file => {
        file.isUploaded = true
        return file
      })
    } catch (error) {
      console.error(error)
    }
  },
  async saveMetrics ({ rootState, commit }, payload) {
    try {
      const response = await axios.put(`/update_quality_metrics`, payload)
      // commit('setSaveMetrics', response)
      rootState.TaskList.selectedTask.quality_metrics = response
      return response
    } catch (error) {
      console.info(error)
      commit('Notify/setError', 'При редактировании произошла ошибка. Попробуйте позже.', { root: true })
    }
  },
}
